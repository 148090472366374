import React from "react";

const BrandAppleMusic = React.forwardRef(
  ({ color = "black", size = 24, ...props }, ref) => (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M18.5 24h-13c-3 0-5.5-2.5-5.5-5.5v-13C0 2.5 2.5 0 5.5 0h13c3 0 5.5 2.5 5.5 5.5v13c0 3-2.5 5.5-5.5 5.5zm-.9-13.9V4.2c0-.3-.2-.5-.6-.5-1.7.2-6.9 1.3-7.9 1.6-.3 0-.5.2-.6.5-.1 3.1 0 6.2 0 9.2 0 .4-.2.8-.6.9-1.5.2-3.4.7-3.1 2.6.2 1.4 1.8 1.8 3 1.4 1.1-.3 1.7-1.4 1.6-2.5V9.5c0-.3.1-.4.4-.5 2.2-.5 4.3-1 6.5-1.4.2 0 .3.1.3.2.1 1.9 0 3.9 0 5.8-.1.3-.2.5-.5.5-.7.2-1.5.2-2.1.6-.8.4-1.2 1.2-1 2 .4 2.1 3.6 1.9 4.4.2.5-1.9 0-4.6.2-6.8z" />
    </svg>
  )
);

export default BrandAppleMusic;
